<!--电梯模块--设备页-->
<template>
  <div id="equipment">
    <div class="header">
      <a-form-model :model="form" layout="inline">
        <a-form-model-item :label="$t('elevatorequipment.a1')">
          <a-select v-model="form.type" style="width: 180px">
            <a-select-option v-for="(item, value) in typeselect" :key="value">{{
               $t(item.name)
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div class="form_col">
        <a-button type="primary" @click="inquire">{{this.$t('elevatorequipment.a12')}}</a-button>
      </div>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('elevatorequipment.a5')">
      <template v-if="form.type!=2">
        <my-elevatorcard
          v-for="item in tenantlist"
          :key="item.id"
          :cardContent="item"
        ></my-elevatorcard>
      </template>
      <template v-if="form.type!=1"> 
        <my-elevatorcard
          v-for="item in cardlist"
          :key="item.id"
          :cardContent="item"
        ></my-elevatorcard>
      </template>
      <div class="empty" v-for="n in 10" :key="'m'+n"></div>
      <my-error v-show="id.build === 0"></my-error>
    </div>
    <div class="footer">
       <ul class="express">
        <li class="expressli" v-for="(item, index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{ $t(item.name) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isEmptrValue,getbuildsiteId,getApiDeviceList,getApiCardReaderList } from "../../api/elevator";
import elevatorcard from "./components/elevatorCard";
import errorPage from "./components/errorPage";
const readerCardStatus = {
  0:{
    iconNo:174,
    text:'未关联-机器异常'
  },
  1:{
    iconNo:181,
    text:'正常'
  }, 
  2:{
    iconNo:174,
    text:'未关联-机器异常'
  },
  3:{
    iconNo:171,
    text:'关联设备已下线-通讯异常'
  },
  4:{
    iconNo:172,
    text:'设置异常'
  },
}
const deviceStatus = {
  0:{
    iconNo:238,
    text:'过期'
  },
  1:{
    iconNo:245,
    text:'正常'
  }, 
  2:{
    iconNo:238,
    text:'通讯异常'
  },
  3:{
    iconNo:239,
    text:'维护'
  },
  4:{
    iconNo:240,
    text:'机器异常'
  },
  5:{
    iconNo:241,
    text:'故障/报警'
  },
  6:{
    iconNo:243,
    text:'维护报警/上下限警报·运行停止异常'
  }
}
export default {
  name: "equipment",
  data() {
    return {
      form: {
        type: 0,
        status:0
      },
      readerCardStatus,
      loading: false,
      tenantlist:[],
      options: [],
      cardlist: [],
      typeselect: [
        { name: "elevatorequipment.a2", value: "全部" },
        { name: "elevatorequipment.a3", value: "控制器" },
        { name: "elevatorequipment.a4", value: "认证终端" },
      ],
      icon: [
        {
          name: "elevatorequipment.a6",
          url: require("../../../public/images/iconEleNo/status/equipment01.png"),
        },
        {
          name: "elevatorequipment.a7",
         url: require("../../../public/images/iconEleNo/status/equipment02.png"),
        },
        {
          name: "elevatorequipment.a8",
          url: require("../../../public/images/iconEleNo/status/equipment03.png"),
        },
        {
          name: "elevatorequipment.a9",
          url: require("../../../public/images/iconEleNo/status/equipment04.png"),
        },
        {
          name: "elevatorequipment.a10",
          url: require("../../../public/images/iconEleNo/status/equipment05.png"),
        },
        {
          name: "elevatorequipment.a11",
          url: require("../../../public/images/iconEleNo/status/equipment06.png"),
        },
      ],
      id:{
        client:0,
        area:0,
        build:0
      }
    };
  },
  async created() {
    this.loading = true;
    await getbuildsiteId(this.$route.query.id).then((res) =>{
      this.id.client = res.data.bd_clientId
      this.id.area = res.data.bd_areaID
      this.id.build = res.data.bd_ID
    }).catch((err) => {
        console.log("访问站点转换出错", err);
        this.loading = false;
    });
    await getApiDeviceList({'mc_areaID':this.id.area,'mc_clientID':this.id.client,'mc_buildingID':this.id.build}).then((res) => { 
          const tempList = []     
          res.data.list.forEach(
            item =>{
              const temp = {"id":item.mc_ID,"name":item.mcName,"type":101,"iconNo":deviceStatus[item.connectStatus].iconNo,"status":item.connectStatus,"ip":item.ip_mcStr}
              tempList.push(temp)
          })
          this.tenantlist = tempList
          this.loading = false;
      })
      .catch((err) => {
        console.log("出错", err);
        this.loading = false;
      });
    await getApiCardReaderList({'ele_clientId':this.id.client,'ele_areaId':this.id.area,'ele_buildingID':this.id.build,"ele_reader_type": 1}).then((res) => { 
        const tempList = []     
        res.data.list.forEach(
          item =>{
            if(item.ele_reader_type===1){
            const temp = {"id":item.ele_ID,"name":item.ele_name,"type":102,"iconNo":readerCardStatus[item.readerStatus].iconNo,"status":1,"ip":item.mcName}
            tempList.push(temp)}
          })
          this.cardlist = tempList
        })
        .catch((err) => {
          console.log("访问设备出错", err);
          this.loading = false;
        });
    this.loading = false;
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.loading = true;
        getApiDeviceList({
            mc_clientID: this.id.client,//this.$store.getters.clientId,
            mc_areaID: this.id.area,//this.$route.query.id,
            mc_buildingID: this.id.build,//this.options[newVal.region].value,
          })
          .then((res) => {
            let { data } = res;
            const tempList = []     
            data.forEach(
            item =>{
              const model = {
                    id:item.mc_ID,
                    deviceName:item.mcName,  //设备名称
                    deviceNum:item.mc_num, //编号
                    deviceFacility:item.mc_facilityNum, //序列号
                    deviceip:item.ip_mcStr,
                    deviceport:item.port_mc,
                    deviceMASK:item.ip_maskStr,
                    deviceGateway:item.ip_gatewayStr,
                    deviceStaus:item.connectStatus, //设备状态
                    deviceReaderStaus:item.readerConnectOk?1:0,//读卡器状态
                    deviceConnectElevatorStaus:item.mcConnectEleStatus?1:0,//设备连接电梯通信状态
                    deviceITM:item.itmFunc?1:0, //ITM投入与退出
                    version:item.appVersion_master, //固件版本
                    versionData:item.appVersion_sub, //固件版本日期
                    directTime:[item.direct_time_beginStr,item.direct_time_endStr],//夜间直驶时间
                    wechatId:item.mc_wxAreaCloudId,//微信云iD
                    lastConnectDeviceIP:item.mc_ReceiveIP,//上次连接设备来源IP地址
                    lastConnectDevicDate:item.mc_connect_time,//上次连接设备时间
              }
              const temp = {"id":item.mc_ID,"name":item.mcName,"type":101,"iconNo":245,"status":item.connectStatus,"ip":item.ip_mcStr}
              tempList.push(temp)
            })
            this.tenantlist = tempList
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
        this.getCardReaderList()
      },
      deep: true,
    },
  },
  components: {
    "my-elevatorcard": elevatorcard,
    "my-error":errorPage
  },
  methods:{
    inquire() {
      this.getCardReaderList()
      this.getDeviceList()
    },
    getDeviceList() { 
      const params = {'mc_areaID':this.id.area,'mc_clientID':this.id.client,'mc_buildingID':this.id.build}
        getApiDeviceList(params).then((res) => { 
          const tempList = []     
          res.data.list.forEach(
            item =>{
              const temp = {"id":item.mc_ID,"name":item.mcName,"type":101,"iconNo":deviceStatus[item.connectStatus].iconNo,"status":item.connectStatus,"ip":item.ip_mcStr}
              tempList.push(temp)
          })
          this.tenantlist = tempList
          this.loading = false;
      })
      .catch((err) => {
        console.log("出错", err);
      });
    },
    getCardReaderList(){
      getApiCardReaderList({'ele_clientId':this.id.client,'ele_areaId':this.id.area,'ele_buildingID':this.id.build,"ele_reader_type": 1}).then((res) => { 
        const tempList = []     
        res.data.list.forEach(
          item =>{
            if(item.ele_reader_type===1){
              const temp = {"id":item.ele_ID,"name":item.ele_name,"type":102,"iconNo":readerCardStatus[item.readerStatus].iconNo,"status":1,"ip":item.mcName}
              tempList .push(temp)
            }
          })
          this.cardlist = tempList
        })
        .catch((err) => {
          console.log("访问设备出错", err);
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.form_col {
  margin-top: 18px;
  margin-right: 15px;
  min-width: 1px;
}
.empty {
  width: 200px;
  height: 1px;
  margin: 0 auto;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>