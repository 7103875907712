<!--电梯模块--设备卡片组件-->
<template>
  <div id="elevatorcard" >
    <div>
      <img :src="getImages" />
    </div>
    <div class="title">
      <span>{{ cardContent.name }}</span>
    </div>
    <div class="footer" @click.stop > 
    {{cardContent.ip}}
    </div>
  </div>
</template>

<script>
export default {
  name: "elevatorcard",
  props: {
    cardContent: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {

  },
  computed: {
    // 卡片图标根据对应iocnNO加载对应图标
    getImages: function () {
      let iconNo = this.cardContent.iconNo.toString();
      return require("../../../../public/images/doorIconNo/" + iconNo + ".png");
    },
  },
};
</script>

<style scoped>
#elevatorcard {
  width: 200px;
  height: 110px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  cursor: pointer;
  transition: 200ms;
}
#elevatorcard:hover {
  border: 1px solid #7682ce;
}
.title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 13px;
  color: #999;
}
.footer {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding-top: 0px;
  padding-left: 10px;
  line-height:24px;
}
</style>